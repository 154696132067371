<template>
 <div class="flex flex-col invoice_table">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="shadow overflow-hidden border-b border-gray-200 sm:">
     <form
      @submit.prevent="addDestination('New Destination added successfully')"
      method="POST"
      class="space-y-8 divide-y divide-gray-200 px-5">
      <div class="space-y-6 sm:pt-5 sm:space-y-5">
       <div>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
         {{ $t("destinationRoutingListAvailable") }}
         <!-- List of routing destination available. -->
        </p>
        <p>
         {{ availableDestinations }}
        </p>
       </div>
       <div class="space-y-6 sm:space-y-5">
        <div
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
         <label
          for="name"
          class="font-semibold capitalize block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ $t("name") }}*
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.nickname"
           type="text"
           name="name"
           id="name"
           autocomplete="name"
           class="max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300 rounded" />
         </div>
        </div>
        <div
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
         <label
          for="name"
          class="font-semibold block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {{ $t("districts") }}*
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.geographic"
           type="text"
           name="name"
           id="name"
           required
           class="max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300 rounded" />
         </div>
        </div>
        <div
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
         <label
          for="destination-1"
          class="font-semibold block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Destination 1*
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.destination1"
           type="number"
           name="destination-1"
           id="destination-1"
           autocomplete="destination-1"
           class="max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300 rounded" />
         </div>
        </div>
        <div
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
         <label
          for="destination-2"
          class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Destination 2
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.destination2"
           type="number"
           name="destination-2"
           id="destination-2"
           autocomplete="destination-2"
           class="max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300 rounded" />
         </div>
        </div>
        <div
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
         <label
          for="destination-3"
          class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Destination 3
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           v-model="this.form.destination3"
           type="number"
           name="destination-3"
           id="destination-3"
           autocomplete="destination-3"
           class="max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300 rounded" />
         </div>
        </div>
        <div
         class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
         <label
          for="destination-4"
          class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Destination 4
         </label>
         <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input
           type="number"
           name="destination-4"
           id="destination-4"
           autocomplete="destination-4"
           class="max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300 rounded" />
         </div>
        </div>
       </div>
      </div>

      <div class="py-5">
       <div class="flex justify-end">
        <a
         href="javascript:void(0)"
         @click="this.$emit('toggle-add-destination')"
         class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
         {{ $t("ticket.cancel") }}
        </a>
        <button
         type="submit"
         class="rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white">
         {{ $t("ticket.send") }}
        </button>
       </div>
      </div>
     </form>
    </div>
   </div>
  </div>
 </div>
 <ConfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow" />
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";

const account = localStorage.getItem("account");

export default {
 name: "DestinationForm",
 components: { ConfirmationDialog, Loading },
 props: ["availableDestinations", "account", "id", "data"],

 data() {
  return {
   account,
   showMessage: false,
   messageToShow: "",
   confirmationMessage: "",
   subMessageToShow: "",
   form: {
    nickname: "",
    destination1: "",
    destination2: "",
    destination3: "",
    destination4: "",
    geographic: "",
   },
   geo_routing_list_id: "",
  };
 },

 methods: {
  async getData() {
   console.log("axios.get ~ res.data:", this.data);
   let destData = this.data.filter(res => res.id == this.id)[0];
   console.log("getData ~ destData:", destData);
   this.form.nickname = destData.nickname;
   this.form.destination1 = destData.destination1;
   this.form.destination2 = destData.destination2;
   this.form.destination3 = destData.destination3;
   this.form.destination4 = destData.destination4;
   this.form.geographic = destData.geographic;
   this.geo_routing_list_id = destData.geo_routing_list_id;
  },
  async addDestination(msg) {
   const options = {
    method: "put",
    url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingDestinations/${
     this.id
    }`,
    params: {
     customerAccount: `${this.account}`,
    },
    data: this.form,
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then(res => {
     this.form.nickname = "";
     this.form.destination1 = "";
     this.form.destination2 = "";
     this.form.destination3 = "";
     this.form.destination4 = "";
     this.form.geographic = "";
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("successMsg"));
    })
    .catch(err => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
 mounted() {
  if (this.id) this.getData();
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style></style>
